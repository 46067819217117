import enumOverrides from '../../enum-overrides';

export default class FormOverviewController {
  constructor ($scope, $sce) {
    this.scope = $scope;
    this.sce = $sce;
    this.lodash = _;

    this._resetState();
    this._initWatchers();
  }

  /**
   * Init necessary watchers on the scope
   * @private
   */
  _initWatchers () {
    this.scope.$watch(() => {
      return this.formData;
    }, () => {
      if (this.formData) {
        this._prepareDataFromFormData();
      }
    });

    this.scope.$watch(() => {
      return this.formConfig;
    }, () => {
      if (this.formConfig) {
        this._prepareDataFromFormData();
      }
    });
  }

  /**
   *
   * @private
   */
  _prepareDataFromFormData () {
    const newData = {};

    _.each(this.formConfig, (groupValues, groupKey) => {
      if ((['contact', 'rsvp']).indexOf(groupKey) === -1) {
        newData[groupKey] = groupValues;
        return;
      }

      _.each(groupValues, (field, fieldKey) => {
        const fieldPath = groupKey + '.' + fieldKey;
        let value = _.get(this.formData, fieldPath);

        console.log(field, value);

        switch (field.type) {
          case 'string':
            if(field.enum) {
              let enumToUse = field.enum;
              const override = _.get(enumOverrides, fieldPath);

              if(!_.isUndefined(override)) {
                enumToUse = override;
              }

              value = _.get(enumToUse, value, 'Keine Auswahl');
            }

            if(field.multiline) {
              value = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            break;
          case 'boolean':
            value = value ? 'Ja' : 'Nein';
            break;
          case 'date':
            if (value) {
              const dateObject = moment(value, 'YYYY-MM-DD');
              value = dateObject.format('DD.MM.YYYY');
            } else {
              value = null;
            }
            break;
          case 'datetime':
            if (value) {
              const dateObject = moment(value);
              value = dateObject.format('DD.MM.YYYY HH:mm');
            } else {
              value = null;
            }
            break;
        }

        newData[fieldPath] = {
          label: field.label,
          value: this.sce.trustAsHtml(value)
        };
      });
    });

    this._setState({
      ...this.state,
      data: newData
    });
  }

  /**
   *
   * @param state
   * @private
   */
  _setState (state) {
    this.state = state;
    this.scope.$applyAsync();
  }

  /**
   *
   * @private
   */
  _resetState () {
    this._setState({
      data: {}
    });
  }
}

FormOverviewController.$inject = [
  '$scope',
  '$sce'
];
